
import Page from "@views/page/components/page.vue";
import { Options, Vue } from "vue-property-decorator";
import FilterModel from "../components/filter-model";
import GetEndpointsStatsRequest from "@dataSource/LoggerMicroService/Services/Models/Request/EndpointsStatisticsService/GetEndpointsStatsRequest";
import EnumHardwareType from "@dataSource/Tirscript/LoggerMicroService/Enums/EnumHardwareType";
import GetHardwareLogsResponse from "@dataSource/LoggerMicroService/Services/Models/Response/LogGetterService/GetHardwareLogsResponse";
import StoreFilterModel from "../components/store-filter-model";
import ChartStatistic from "@/views/components/chart-statistic.vue";
import ChartDatasetsModel from "@/views/components/chartDatasetsModel";
import ChartDataModel from "@/views/components/chartDataModel";
import getUTC from "@/function/getUTC"

@Options({
  name: "cpu-load-page",
  components: {
    Page,
    ChartStatistic,
  },
})
export default class CpuLoadPage extends Vue {
  transactionChartLabels = [];
  filterModel: FilterModel = new FilterModel();
  isLoading = false;
  sendRequest: number = null;

  isLiveReload = false;
  timerId = 0;

  error = {
    isSuccess: true,
    errorMessage: ""
  }

  datasets = new ChartDatasetsModel({
    label: "Нагрузка на процессор",
    data: [],
    borderColor: "#ac65e4",
    backgroundColor: "#ac65e4",
    yAxisID: "y",
    pointStyle: "circle",
  });

  chartModel = new ChartDataModel({
    labels: [],
    datasets: [this.datasets],
  });

  unmounted() {
    clearInterval(this.timerId)
  }

  liveReload(value) {
    this.isLiveReload = value
    if (value) {
      this.timerId = setInterval(() => {
        this.filterModel.DateTo = new FilterModel().DateTo
        this.onclickFilterChange()
      }, 5000)
    } else {
      clearInterval(this.timerId)
    }
  }

  onclickFilterChange() {
    this.getExecuteTime();
  }

  async getExecuteTime() {
    let request = {
      ProjectLogGroupId: this.filterModel.source,
      Type: EnumHardwareType.Cpu,
      ToDate: this.filterModel.DateTo,
      FromDate: this.filterModel.DateFrom,
    };
    let res: GetHardwareLogsResponse;
    let tmpFilter = getUTC(request)
    try {
      res = await this.$api.LogGetterService.getHardwareLogsAsync(tmpFilter);
      this.isLoading = false;
    } catch (error) {
      this.error.isSuccess = false;
      this.error.errorMessage = error;
      this.isLoading = false;
    }
    this.datasets.data.splice(0, this.datasets.data.length);
    this.chartModel.labels.splice(0, this.chartModel.labels.length);
    res.Data.forEach((element) => {
      this.datasets.data.push(element.Value);
      this.chartModel.labels.push(element.Date.toString());
    });
    this.isLoading = false;
  }
}
